import React from 'react'
import { showDate } from 'utilities/form'
import { getLimit, getPagination } from 'utilities/pagination'
import { request } from 'utilities/request'
import { Link, Text } from 'components/core'

export const columns = [
  {
    id: 'ticketId',
    label: `field.ticketNo`,
    render: ({ row: { ticketType, ticketId } }) => {
      const type = ticketType.split('_')[1].toLowerCase()
      return (
        <Link variant="primaryLink" href={`/${type}/${ticketId}/view`}>
          {ticketId}
        </Link>
      )
    },
  },
  {
    id: 'transDate',
    label: `inventory.field.transDate`,
    render: ({ row }) => showDate(row.transDate),
  },
  // {
  //   id: 'locationName',
  //   label: `inventory.field.technician`,
  // },
  {
    id: 'quantity',
    label: 'inventory.field.quantity',
    align: 'right',
    render: ({ row: { quantity } }) =>
      quantity >= 0 ? quantity : <Text color="error.1">{quantity}</Text>,
  },
]

export const handlers = ({ setState, session, app, value, setPagination }) => ({
  handleLoad: async ({ pagination = {} } = {}) => {
    if (!value.productVariantId) return

    const resp = await getData({ session, app, value, pagination })
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ session, app, value, pagination }) {
  const variables = { input: getDataInput({ pagination, value }) }
  const query = `
    query($input: InventoryQueryInput!) {
      inventoryLinkCount(input: $input)
      inventoryLinks(input: $input) {
        ticketId
        ticketType
        transDate
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryLinks,
    pagination: getPagination(page, countPerPage, data.inventoryLinkCount),
  }
}

function getDataInput({ pagination, value }) {
  const input = {
    locationId: value.locationId,
    productVariantId: value.productVariantId,
    orderBy: [{ key: 'createdAt', sort: 'DESC' }],
  }
  if (value.locationType) input.locationType = value.locationType
  if (pagination) input.limit = getLimit(pagination)
  return input
}

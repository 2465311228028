export default {
  'adjust.title.list': '調整目錄',
  'adjust.title.add': '新增調整單',
  'adjust.title.edit': '修改調整單',
  'adjust.title.view': '調整單詳情',
  'adjust.section.basic': '基礎信息',
  'adjust.section.part': '調整零件',
  'adjust.field.type': '類型',
  'adjust.type.SELF_USE': '整機自用',
  'adjust.type.DISCARD': '新品報廢',
  'adjust.type.ADJUST': '差數調整',
}

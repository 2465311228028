import { request } from 'utilities/request'
import { getLimit, getPagination } from 'utilities/pagination'
import { textFilter } from 'utilities/filter'

export const filters = ({ filterInput, setFilterInput }) => [
  {
    id: 'name',
    label: 'part.field.partName',
    input: textFilter({ filterInput, setFilterInput }),
  },
  {
    id: 'sku',
    label: 'part.field.sku',
    input: textFilter({ filterInput, setFilterInput }),
  },
]

export const columns = ({ action }) => [
  {
    id: 'productVariantName',
    label: 'part.field.partName',
  },
  {
    id: 'sku',
    label: 'part.field.sku',
  },
  {
    id: 'location',
    label: 'part.field.location',
  },
  {
    id: 'price',
    label: 'part.field.price',
    align: 'right',
  },
  {
    id: 'monthlyAvgQuantity',
    label: 'part.field.monthlyAvgQuantity',
    align: 'right',
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    align: 'right',
  },
]

export const handlers = ({
  setState,
  session,
  app,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ session, app, filterValues, pagination }) {
  const variables = { input: getDataInput({ pagination, filterValues }) }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryCount(input: $input)
      inventoryList(input: $input) {
        productVariantId
        productVariantName
        sku
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryList,
    pagination: getPagination(page, countPerPage, data.inventoryCount),
  }
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const input = {
    locationType: 'PART_WAREHOUSE',
    productVariantName: name ? name.value : null,
    sku: sku ? sku.value : null,
    orderBy: [{ key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

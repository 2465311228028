import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { TextInput } from 'components/form'
import { TOKEN_SET, STAFF_SET } from 'constants/actionType'

export const initialState = () =>
  initializeState({
    username: '',
    password: '',
  })

const validation = {
  username: [{ type: 'required', message: 'error.required' }],
  password: [{ type: 'required', message: 'error.required' }],
}

export const fields = ({ state, setState }) => {
  const handleChange = (id) => handleTextChange(id, state, setState, validation)
  return {
    username: (
      <TextInput
        id="username"
        placeholder="login.field.username"
        value={state.username}
        autoComplete="username"
        onChange={handleChange('username')}
        errMsg={state.__error__.username}
      />
    ),
    password: (
      <TextInput
        id="password"
        type="password"
        placeholder="login.field.password"
        value={state.password}
        autoComplete="current-password"
        onChange={handleChange('password')}
        errMsg={state.__error__.password}
      />
    ),
  }
}

export const handlers = ({ state, setState, session, app, history }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) {
      return
    }

    const { username, password } = state
    const variables = { username, password }
    const query = `
      mutation($username: String!, $password: String!) {
        createToken(username: $username, password: $password) {
          accessToken
          refreshToken
          claim
        }
      }
    `
    const [ok, data] = await request({ query, variables }, { session })
    if (!ok) return

    const { accessToken, refreshToken, claim } = data.createToken
    app.dispatch({ type: STAFF_SET, staff: claim })
    app.dispatch({ type: TOKEN_SET, token: { accessToken, refreshToken } })
    history.push('/dashboard')
  },
})

export default {
  'error.inventoryBalance.insufficientQuantity': '庫存數量不足',
  'inventory.title': '零件庫存',
  'inventory.title.allList': '總倉',
  'inventory.title.techList': '攜出目錄',
  'inventory.title.history': '存量歷史紀錄',
  'inventory.field.tech': '維修人員',
  'inventory.field.spu': '商品名稱',
  'inventory.field.sku': '品號',
  'inventory.field.options': '屬性',
  'inventory.field.quantity': '數量',
  'inventory.field.balance': '庫存數量',
  'inventory.field.deptBalance': '業務數量',
  'inventory.field.lockQuantity': '鎖貨數量',
  'inventory.field.availableQuantity': '可用數量',
  'inventory.field.totalQuantity': '總數量',
  'inventory.field.ticketId': '單號',
  'inventory.field.ticketType': '類型',
  'inventory.field.transDate': '日期',
  'inventoryDiff.title.list': '三方系統庫存差異',
  'inventoryDiff.field.vendor': '三方系統',
  'inventoryDiff.field.quantity': '數量',
  'inventoryDiff.field.vendorQuantity': '三方數量',
}

export const printHtml = ({ title, header, footer, content, message }) => {
  try {
    const frame = document.getElementById('ticket-default')
    const doc = (frame.contentWindow || frame.contentDocument).document
    doc.write(renderHead(title, message))
    doc.write('<body onload="this.focus(); this.print();">')
    doc.write('<table border="0" width="100%">')

    if (header) {
      doc.write(renderHeader(header, message))
    }

    if (footer) {
      doc.write(renderFooter(footer, message))
    }

    doc.write(`<tbody class="tbody"><tr><td width="100%">`)

    content.forEach((item) => {
      switch (item.type) {
        case 'title':
          doc.write(renderTitle(item.value, message))
          break
        case 'subTitle':
          doc.write(renderSubTitle(item.value, message))
          break
        case 'field':
          doc.write(renderFields(item.value, message))
          break
        case 'list':
          doc.write(renderTable(item.value, message))
          break
        default:
      }
    })

    doc.write('</td></tr></tbody>')
    doc.write('</table>')
    doc.write('</body>')
    doc.close()
  } catch (e) {
    console.error(e)
  }
}

function renderHead(title, message) {
  return `
    <head>
      <title>${message({ id: title })}</title>
      <style>
        html, body {
          margin: 0;
          padding: 0;
        }
        @page {
          margin: 16px;
        }
        .grid-container {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          margin: 16px 0;
        }
        .grid-item {
          padding: 4px;
        }
        .theader {
          position: sticky;
          top: 0;
          right: 0;
          left: 0;
        }
        .tfooter {
          position: sticky;
          bottom: 0;
          right: 0;
          left: 0;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 8px;
          border-bottom: 2px solid #000;
        }
        .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 32px;
        }
        .title {
          margin: 0;
          padding: 0 0 8px;
          font-weight: normal;
          font-size: 16px;
        }
        .sub-title {
          margin: 16px 0 0;
          padding: 0 0 8px;
          font-weight: normal;
        }
        .label {
          font-size: 12px;
        }
        .field {
          font-size: 14px;
        }
        .text {
          font-size: 12px;
        }
        .list {
          width: 100%;
          border-collapse: collapse;
          border: 2px solid #000;
        }
        .list th, .list td {
          padding: 4px 8px;
        }
        .list th {
          border-bottom: 2px solid #000;
          font-weight: normal;
          font-size: 14px;
          white-space: nowrap;
        }
        .list td {
          border-bottom: 1px solid #d0d0d0;
          font-size: 14px;
        }
        .list-vertical {
          display: flex;
          flex-direction: column;
        }
        .right {
          text-align: right;
        }
        .left {
          text-align: left;
        }
        .center {
          text-align: center;
        }
        .inactive {
          color: #d0d0d0;
        }
      </style>
    </head>
  `
}

function renderHeader(value, message) {
  const { title, address, phone } = value
  return `
    <thead class="theader">
      <tr>
        <td class="header">
          <div class="title">${message({ id: title })}</div>
          <div class="list-vertical right text">
            <div>${message({ id: address })}</div>
            <div>${message({ id: phone })}</div>
          </div>
        </td>
      </tr>
    </thead>
  `
}

function renderTitle(title, message) {
  return `<h2 class="title">${message({ id: title })}</h2>`
}

function renderSubTitle(title, message) {
  return `<h3 class="sub-title">${message({ id: title })}</h3>`
}

function renderFields(fields, message) {
  return `
    <div class="grid-container">
      ${fields.map((field) => renderField(field, message)).join('')}
    </div>
  `
}

function renderField(field, message) {
  // if (!field.value && field.value !== 0) return ''
  return `
    <div class="grid-item">
      <div class="label">${message({ id: field.label })}</div>
      <div class="field">${field.value || '\u2014'}</div>
    </div>
  `
}

function renderTable(value, message) {
  const { rows, columns } = value
  const cols = columns
    .filter(({ profile = ['print'] }) => profile.includes('print'))
    .filter(({ show = true }) => show)
  return `
    <table class="list">
      <thead>
        <tr>${renderTableHeaderRow(cols, message)}</tr>
      </thead>
      <tbody>
        ${renderTableRows(cols, rows)}
      </tbody>
    </table>
  `
}

function renderTableHeaderRow(columns, message) {
  return columns
    .map((column) => {
      const { align = 'left' } = column
      const cell = message({ id: column.label })
      return `<th class="${align}">${cell}</th>`
    })
    .join('')
}

function renderTableRows(columns, rows) {
  if (!rows || rows.length === 0) {
    return `<tr><td class="center inactive" colspan="${columns.length}">NO DATA</td></tr>`
  }
  return rows.map((row) => `<tr>${renderTableRow(columns, row)}</tr>`).join('')
}

function renderTableRow(columns, row) {
  return columns
    .map((column) => {
      const { align = 'left' } = column
      let cell = renderTableCell(column, row)
      if (cell === undefined || cell === null) cell = ''
      return `<td class="${align}">${cell}</td>`
    })
    .join('')
}

function renderTableCell(column, row) {
  if (column.renderPrint) return column.renderPrint({ row })
  if (column.render) return column.render({ row })
  return row[column.id]
}

function renderFooter(values, message) {
  const renderValue = (value) => {
    if (value === '__INPUT__') {
      return '<div style="flex: 1; border-bottom: 2px solid #000;">&nbsp;</div>'
    }
    return `<div class="field">${value}</div>`
  }
  const fields = values.map((item) => {
    const label = message({ id: item.label })
    const value = renderValue(item.value)
    return `<div class="label" style="padding-right: 4px">${label}:</div>${value}`
  })
  return `
    <tfoot class="tfooter">
      <tr>
        <td class="footer">
          ${fields.join('&nbsp;')}
        </td>
      </tr>
    </tfoot>
  `
}

import React from 'react'
import { initializeState, handleTextChange, validateForm } from 'utilities/form'
import { request } from 'utilities/request'
import { TextInput, NumberInput, Switch } from 'components/form'
import { Definition } from 'components/core'

export const initialState = (value = {}) => ({
  id: value.id || '',
  ...initializeState({
    name: value.name || '',
    sku: value.sku || '',
    barcode: value.barcode || '',
    price: value.price || 0,
    location: value.extra?.location || '',
    device: value.extra?.device || '',
    minQuantity: value.extra?.minQuantity || 0,
    isActive: value.status !== 'INACTIVE',
  }),
})

const validation = {
  name: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 25, message: ['error.maxLength', { val: 25 }] },
  ],
  sku: [
    { type: 'required', message: 'error.required' },
    { type: 'maxLength', val: 32, message: ['error.maxLength', { val: 25 }] },
  ],
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)

  return {
    name: (
      <TextInput
        id="name"
        label="part.field.partName"
        placeholder="part.field.partName"
        value={state.name}
        onChange={onTextChange('name')}
        errMsg={state.__error__.name}
      />
    ),
    sku: (
      <TextInput
        id="sku"
        label="part.field.sku"
        placeholder="part.field.sku"
        value={state.sku}
        onChange={onTextChange('sku')}
        errMsg={state.__error__.sku}
      />
    ),
    barcode: (
      <TextInput
        id="barcode"
        label="part.field.barcode"
        placeholder="part.field.barcode"
        value={state.barcode}
        onChange={onTextChange('barcode')}
        errMsg={state.__error__.barcode}
      />
    ),
    location: (
      <TextInput
        id="location"
        label="part.field.location"
        placeholder="part.field.location"
        value={state.location}
        onChange={onTextChange('location')}
        errMsg={state.__error__.location}
      />
    ),
    device: (
      <TextInput
        id="device"
        label="part.field.device"
        placeholder="part.field.device"
        value={state.device}
        onChange={onTextChange('device')}
        errMsg={state.__error__.device}
      />
    ),
    // minQuantity: (
    //   <NumberInput
    //     id="minQuantity"
    //     label="part.field.minQuantity"
    //     placeholder="part.field.minQuantity"
    //     min={0}
    //     value={state.minQuantity}
    //     onChange={onTextChange('minQuantity')}
    //     errMsg={state.__error__.minQuantity}
    //   />
    // ),
    price: (
      <NumberInput
        type="decimal"
        id="price"
        label="part.field.price"
        placeholder="part.field.price"
        min={0}
        value={state.price}
        onChange={onTextChange('price')}
        errMsg={state.__error__.price}
      />
    ),
    isActive: (
      <Definition
        direction="row"
        label="part.field.isActive"
        labelProps={{ flex: 1 }}
      >
        <Switch
          checked={state.isActive}
          onClick={() => {
            setState({ ...state, isActive: !state.isActive })
          }}
        />
      </Definition>
    ),
  }
}

export const handlers = ({ app, session, state, setState, action }) => ({
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok] = state.id
      ? await editPart({ state, app, session })
      : await addPart({ state, app, session })

    if (ok) {
      action.handleLoad()
      action.handleClose()
    }

    return true
  },
})

async function addPart({ state, app, session }) {
  const input = getSubmitInput(state)
  const variables = { input }
  const query = `
    mutation($input: PartInput!) {
      addPart(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editPart({ state, app, session }) {
  const input = getSubmitInput(state)
  const variables = { id: state.id, input }
  const query = `
    mutation($id: ID!, $input: PartInput!) {
      editPart(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  return {
    spu: state.name,
    sku: state.sku,
    barcode: state.barcode,
    price: parseFloat(state.price),
    extra: {
      location: state.location,
      device: state.device,
      minQuantity: state.minQuantity,
    },
    status: state.isActive ? 'ACTIVE' : 'INACTIVE',
  }
}

import React from 'react'
import { Text } from 'components/core'
import { request } from 'utilities/request'

export const parseTicketItems = (ticketItems) => {
  if (!ticketItems) return []
  return ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    productVariantName: item.productVariantName,
    isGift: item.extra?.isGift,
    barcode: item.extra?.barcode,
    batchNo: item.extra?.batchNo,
    balance: item.balance || 0,
    deptBalance: item.deptBalance || 0,
    quantity: item.quantity,
    price: item.extra?.price || 0,
  }))
}

export const setBalances = (ticketItems, balances, key = 'balance') => {
  ticketItems.forEach((ticketItem) => {
    const balance = balances.find(
      (item) => ticketItem.productVariantId === item.productVariantId,
    )
    ticketItem[key] = balance ? balance.quantity : 0
  })
}

export const getEditableBalance = (profile, oldQuantity, balance) => {
  const quantity = parseInt(oldQuantity || 0)
  const newBalance = parseInt(balance || 0)
  if (profile !== 'edit') return newBalance
  return newBalance + quantity
}

export const getDisplayBalance = (profile, oldQuantity, balance) => {
  const quantity = parseInt(oldQuantity || 0)
  const newBalance = getEditableBalance(profile, oldQuantity, balance)
  if (newBalance > 0 && newBalance >= quantity) return newBalance
  return <Text color="error.1">{newBalance}</Text>
}

export const getRecipients = (value = [], message) => {
  return [
    {
      id: '__INDIVIDUAL__',
      name: message({ id: 'dispatch.recipient.individual' }),
    },
    ...value,
  ]
}

export async function handleDelete(module, { session, app, id, hash, action }) {
  const name = module.charAt(0).toUpperCase() + module.slice(1)
  const input = { hash }
  const variables = { id, input }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      cancel${name}Ticket(id: $id, input: $input)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok && action) action.handleLoad()
  return ok
}

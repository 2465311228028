import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  initializeState,
  handleTextChange,
  validateForm,
  showDate,
  getSelectOptions,
  getSelectOption,
  handleSelectChange,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { request } from 'utilities/request'
import { Definition, Button, Table } from 'components/core'
import { TextInput, TextArea, Select, NumberInput } from 'components/form'
import { MdDelete } from 'react-icons/md'
import { printHtml } from 'utilities/print'
import { handleDelete } from 'actions/ticket'

export const initialState = (value = {}) => ({
  id: value.id,
  status: value.status || 'PENDING',
  hash: value.hash,
  transDate: showDate(value.transDate),
  oldTicketItems: value.oldTicketItems || [],
  ticketItems: value.ticketItems || [],
  parts: value.parts || [],
  partOptions: getSelectOptions(value.parts),
  warehouses: getSelectOptions(value.warehouses),
  warehouseName: value.warehouseName || '',
  ...initializeState({
    warehouseId: getSelectOption(value.warehouseId),
    ticketNo: value.ticketNo || '',
    memo: value.memo || '',
  }),
})

const validation = {
  warehouseId: [{ type: 'required', message: 'error.required' }],
  ticketItems: [{ type: 'required', message: 'error.required' }],
}

const defs = [
  { id: 'id', label: 'field.ticketId' },
  { id: 'transDate', label: 'field.date' },
  { id: 'ticketNo', label: 'field.ticketNo' },
  {
    id: 'warehouseId',
    label: 'field.warehouse',
    render: (state) => state.warehouseName,
  },
  { id: 'memo', label: 'field.memo' },
]

const columns = [
  { id: 'productVariantName', label: 'field.partName' },
  { id: 'sku', label: 'field.sku' },
  { id: 'quantity', label: 'field.quantity', align: 'right' },
]

export const labels = ({ state }) => {
  const content = defs.reduce((result, { id, label, render }) => {
    const value = render ? render(state) : state[id]
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})

  content.ticketItems = <Table columns={columns} rows={state.ticketItems} />
  return content
}

export const fields = ({ state, setState }) => {
  const onTextChange = (id) => handleTextChange(id, state, setState, validation)
  const onSelectChange = (id) =>
    handleSelectChange(id, state, setState, validation)

  return {
    ticketNo: (
      <TextInput
        id="ticketNo"
        label="field.ticketNo"
        value={state.ticketNo}
        onChange={onTextChange('ticketNo')}
      />
    ),
    warehouseId: (
      <Select
        id="warehouseId"
        label="field.warehouse"
        isClearable={false}
        options={state.warehouses}
        value={state.warehouseId}
        onChange={onSelectChange('warehouseId')}
      />
    ),
    memo: (
      <TextArea
        id="memo"
        label="field.memo"
        value={state.memo}
        onChange={onTextChange('memo')}
      />
    ),
    partFilter: (
      <Select
        id="partFilter"
        placeholder="field.partFilter"
        options={state.partOptions}
        value=""
        onChange={(item) => {
          if (!item) return
          const { value: id } = item
          addTicketItem({ state, setState, id })
        }}
      />
    ),
    ticketItems: (
      <Table
        columns={[
          {
            id: 'productVariantName',
            label: 'field.partName',
          },
          {
            id: 'sku',
            label: 'field.sku',
          },
          {
            id: 'quantity',
            label: 'field.quantity',
            width: '128px',
            render: ({ row, index }) => (
              <NumberInput
                fieldProps={{ m: 0 }}
                min="1"
                value={row.quantity}
                onChange={(value) => {
                  const ticketItems = cloneDeep(state.ticketItems)
                  const product = ticketItems[index]
                  product.quantity = value
                  ticketItems.splice(index, 1, product)
                  setState({ ...state, ticketItems })
                }}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            render: ({ index }) => (
              <Button
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const ticketItems = [...state.ticketItems]
                  ticketItems.splice(index, 1)
                  setState({ ...state, ticketItems })
                }}
              />
            ),
          },
        ]}
        rows={state.ticketItems}
      />
    ),
  }
}

function addTicketItem({ state, setState, id }) {
  const ticketItems = cloneDeep(state.ticketItems)
  const part = state.parts.find((item) => item.id === id)
  const ticketItem = {
    productVariantId: id,
    productVariantName: part.name,
    sku: part.sku,
    quantity: 1,
  }
  ticketItems.push(ticketItem)
  setState({ ...state, ticketItems })
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  message,
  history,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, id })
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editPartBuy(state, app, session)
      : await addPartBuy(state, app, session)
    if (!ok) return
    if (!id) id = data.addPartBuyTicket

    history.push(`/buy/${id}/view`)
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('partBuy', { session, app, id, hash })
    if (!ok) return false

    history.push('/buy')
    return true
  },
  handlePrint: () => {
    const title = 'buy.title.view'
    const field = defs.map(({ id, label }) => ({ label, value: state[id] }))
    const list = { columns, rows: state.ticketItems }
    const content = [
      { type: 'title', value: title },
      { type: 'field', value: field },
      { type: 'list', value: list },
    ]
    printHtml({ title, content, message })
  },
})

async function getData({ app, session, id }) {
  const locationInput = { type: ['PART_WAREHOUSE'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
        type
      }
      parts {
        id
        name
        sku
      }
      partBuyTicket(id: $id) {
        ticketNo
        toLocationId
        toLocationName
        transDate
        status
        hash
        extra
      }
      partBuyTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = data.partBuyTicket || {}
  const ticketItems = data.partBuyTicketItems || []

  return {
    id,
    hash: ticket.hash,
    ticketNo: ticket.ticketNo,
    warehouseId: ticket.toLocationId,
    warehouseName: ticket.toLocationName,
    transDate: ticket.transDate,
    memo: ticket.extra?.memo,
    status: ticket.status,
    ticketItems,
    oldTicketItems: cloneDeep(ticketItems),
    warehouses: data.locations,
    parts: data.parts,
  }
}

async function addPartBuy(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addPartBuyTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editPartBuy(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editPartBuyTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
  }))
  const isKeyEqual = (item, newItem) => {
    return item.productVariantId === newItem.productVariantId
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    ticketNo: state.ticketNo,
    toLocationId: state.warehouseId.value,
    extra: {
      memo: state.memo,
    },
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

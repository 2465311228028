import React from 'react'
import { hasAccess } from 'utilities/permission'
import {
  MdDashboard,
  MdStorage,
  MdCardGiftcard,
  MdLocalShipping,
  MdSettings,
} from 'react-icons/md'

export const navLinks = (staff) => [
  {
    id: 'dashboard',
    label: 'dashboard.title',
    icon: <MdDashboard />,
    path: '/dashboard',
    show: true,
  },
  {
    id: 'ticket',
    label: 'ticket.title',
    icon: <MdCardGiftcard />,
    items: [
      {
        id: 'buyList',
        label: 'buy.title.list',
        path: '/buy',
        show: hasAccess(staff, 'partBuyTickets'),
      },
      {
        id: 'checkoutList',
        label: 'checkout.title.list',
        path: '/checkout',
        show: hasAccess(staff, 'partCheckOutTickets'),
      },
      {
        id: 'checkinList',
        label: 'checkin.title.list',
        path: '/checkin',
        show: hasAccess(staff, 'partCheckinTickets'),
      },
      {
        id: 'sellList',
        label: 'sell.title.list',
        path: '/sell',
        show: hasAccess(staff, 'sellTickets'),
      },
      {
        id: 'adjustList',
        label: 'adjust.title.list',
        path: '/adjust',
        show: hasAccess(staff, 'adjustTickets'),
      },
      {
        id: 'moveList',
        label: 'move.title.list',
        path: '/move',
        show: hasAccess(staff, 'moveTickets'),
      },
    ],
  },
  {
    id: 'inventoryList',
    label: 'inventory.title',
    icon: <MdStorage />,
    path: '/inventory/all',
    show: hasAccess(staff, 'inventoryAllList'),
  },
  {
    id: 'inventoryTechList',
    label: 'inventory.title.techList',
    icon: <MdLocalShipping />,
    path: '/inventory/technician',
    show: hasAccess(staff, 'inventoryTechList'),
  },
  {
    id: 'setting',
    label: 'setting.title',
    icon: <MdSettings />,
    items: [
      {
        id: 'technicianList',
        label: 'technician.title.list',
        path: '/technician',
        show: true,
      },
      {
        id: 'partList',
        label: 'part.title.list',
        path: '/part',
        show: hasAccess(staff, 'parts'),
      },
      {
        id: 'warehouseList',
        label: 'warehouse.title.list',
        path: '/warehouse',
        show: true,
      },
    ],
  },
]

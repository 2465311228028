import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { getStatusColor } from 'constants/status'
import { Box } from 'reflexbox'
import { initialState, fields, labels, handlers } from 'models/movePage'
import {
  Center,
  Grid,
  Button,
  Link,
  Section,
  StatusChip,
  Popover,
} from 'components/core'
import Page from 'components/Page'
import { MdArrowBack, MdPrint } from 'react-icons/md'
import { renderDeleteLink } from 'utilities/app'

export default ({ match, profile }) => {
  const { id } = match.params
  const history = useHistory()
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState(initialState())

  const readonly = profile === 'view'
  const form = readonly
    ? labels({ state, setState })
    : fields({ app, session, state, setState, profile })
  const handler = handlers({
    app,
    session,
    state,
    setState,
    message,
    history,
    profile,
    id,
  })

  useEffect(() => {
    handler.handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, profile])

  return (
    <>
      <Page
        navId="moveList"
        title={`move.title.${profile}`}
        titleExtra={
          <StatusChip
            show={readonly}
            ml={2}
            label={`status.${state.status}`}
            color={getStatusColor(state.status)}
            bg="light.1"
          />
        }
        action={
          <Center>
            <Link
              variant="link"
              icon={<MdArrowBack />}
              text="btn.back"
              href="/move"
            />
            <Link
              ml={3}
              show={readonly}
              variant="link"
              icon={<MdPrint />}
              onClick={handler.handlePrint}
            />
            <Button
              ml={3}
              show={!readonly}
              variant="primary"
              text="btn.save"
              type="submit"
            />
            <Popover show={readonly}>
              <Link
                py={2}
                disabled={state.status === 'INACTIVE'}
                variant="primaryLink"
                href={`/move/${id}/edit`}
                text="btn.edit"
              />
              {renderDeleteLink({
                module: 'move',
                session,
                status: state.status,
                onSubmit: handler.handleDelete,
              })}
            </Popover>
          </Center>
        }
        showFooterAction
        as="form"
        onSubmit={handler.handleSubmit}
      >
        <Grid cols={['1fr', '1fr 2fr']} gap={3}>
          <Box>
            <Section title="move.section.basic">
              {form.id}
              {form.transDate}
              {form.fromTechId}
              {form.toTechId}
            </Section>
          </Box>
          <Box>
            <Section title="move.section.part">
              {form.partFilter}
              {form.ticketItems}
            </Section>
          </Box>
        </Grid>
      </Page>
    </>
  )
}

export default {
  'checkout.title.list': '攜出目錄',
  'checkout.title.add': '新增攜出單',
  'checkout.title.edit': '修改攜出單',
  'checkout.title.view': '攜出單詳情',
  'checkout.title.addItem': '新增攜出商品',
  'checkout.title.listItems': '攜出商品詳情',
  'checkout.section.basic': '基礎信息',
  'checkout.section.part': '攜出零件',
  'checkout.field.id': '單號',
  'checkout.field.isUrgent': '急件',
  'checkout.field.isDirect': '公司直出',
  'checkout.field.isSell': '立即銷貨',
  'checkout.field.ticketNo': '廠商單號',
  'checkout.field.transDate': '攜出日期',
  'checkout.field.arriveDate': '到貨日',
  'checkout.field.arrivePeriod': '到貨時段',
  'checkout.field.receiptNo': '發票號碼',
  'checkout.field.sales': '業務人員',
  'checkout.field.memo': '業務備註',
  'checkout.field.dealer': '經銷層',
  'checkout.field.dealerEIN': '統一編號',
  'checkout.field.dealerAddress': '公司地址',
  'checkout.field.billing': '歸帳',
  'checkout.field.recipient': '收貨處',
  'checkout.field.recipientContact': '聯絡人',
  'checkout.field.recipientAddress': '收件地址',
  'checkout.field.recipientEmail': 'Email',
  'checkout.field.recipientPhone': '電話',
  'checkout.field.recipientCellphone': '手機',
  'checkout.field.shipping': '物流',
  'checkout.field.technician': '倉庫',
  'checkout.field.technicianMemo': '倉管備註',
  'checkout.field.productFilter': '輸入搜尋商品名稱',
  'checkout.field.spu': '商品名稱',
  'checkout.field.barcode': '產品條碼',
  'checkout.field.isGift': '贈品',
  'checkout.field.batchNo': '批號',
  'checkout.field.expireAt': '有效日期',
  'checkout.field.balance': '庫存數量',
  'checkout.field.deptBalance': '業務數量',
  'checkout.field.quantity': '數量',
  'checkout.field.price': '金額',
  'checkout.field.status': '狀態',
  'checkout.field.attach': '附件',
  'checkout.arrivePeriod.AM': '早上',
  'checkout.arrivePeriod.PM': '下午',
  'checkout.recipient.individual': '獨立客戶',
}

import React, { useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { SessionContext, AppContext } from 'contexts'
import { initialPage } from 'utilities/pagination'
import { filters, columns, handlers } from 'models/partList'
import { Button, Section, Table } from 'components/core'
import Page from 'components/Page'
import PartDrawer from 'views/PartDrawer'

export default () => {
  const { formatMessage: message } = useIntl()
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState({})
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const { handleLoad } = handlers({
    session,
    app,
    setState,
    setFilterValues,
    setPagination,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <Page
      title="part.title.list"
      navId="partList"
      action={
        <Button
          ml={3}
          variant="secondary"
          text="btn.add"
          onClick={() => handleOpen({})}
        />
      }
    >
      <Section>
        <Table
          columns={columns({
            session,
            app,
            message,
            action: { handleLoad, handleOpen },
          })}
          rows={state}
          filters={filters}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      <PartDrawer
        open={open}
        value={value}
        action={{ handleLoad, handleClose }}
      />
    </Page>
  )
}

export default {
  'error.product.duplicate': '零件名稱已存在',
  'error.productVariant.duplicate': '品號已存在',
  'part.title': '零件管理',
  'part.title.list': '零件目錄',
  'part.title.add': '新增零件',
  'part.title.edit': '更改零件',
  'part.field.partName': '零件名稱',
  'part.field.sku': '零件代碼',
  'part.field.barcode': '零件條碼',
  'part.field.quantity': '數量',
  'part.field.minQuantity': '最低存量',
  'part.field.monthlyAvgQuantity': '月平均量',
  'part.field.price': '單價',
  'part.field.location': '儲放位置',
  'part.field.device': '適用機型',
  'part.field.isActive': '開啟',
  'part.status.ACTIVE': '使用中',
  'part.status.INACTIVE': '已停用',
}

import { request } from 'utilities/request'

export const fetchBalances = async (
  app,
  session,
  locationType,
  locationId,
  productVariantId,
) => {
  if (!productVariantId || productVariantId.length === 0) return []
  const input = { productVariantId, locationType }
  if (locationId) input.locationId = locationId
  const method =
    locationType === 'TECHNICIAN'
      ? 'inventoryBalances'
      : 'inventoryPartBalances'
  const variables = { input }
  const query = `
    query($input: InventoryInput!) {
      ${method}(input: $input) {
        productVariantId
        quantity
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data[method]
}

export const fetchBalance = async (
  app,
  session,
  locationType,
  locationId,
  productVariantId,
) => {
  const variantIdList = [productVariantId]
  const balances = await fetchBalances(
    app,
    session,
    locationType,
    locationId,
    variantIdList,
  )
  return balances.length > 0 ? balances[0].quantity : 0
}

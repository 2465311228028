import React from 'react'
import { getStatusColor } from 'constants/status'
import { request } from 'utilities/request'
import { addDays } from 'utilities/date'
import { getDate, showDate } from 'utilities/form'
import { textFilter, dateFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { LinkButton, StatusChip, Link } from 'components/core'
import { MdEdit } from 'react-icons/md'
import { renderDeleteButton } from 'utilities/app'
import { handleDelete } from 'actions/ticket'

export const filters = ({ filterInput, setFilterInput }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    input: textFilter({ filterInput, setFilterInput }),
  },
  {
    id: 'transDate',
    label: 'field.date',
    input: dateFilter({ filterInput, setFilterInput }),
    defaultValue: [getDate(), addDays(getDate(), 1)],
  },
]

export const columns = ({ session, app, message, action }) => [
  {
    id: 'id',
    label: 'field.ticketId',
    profile: ['html', 'excel'],
    render: ({ row }) => (
      <Link variant="primaryLink" href={`/adjust/${row.id}/view`}>
        {row.id}
      </Link>
    ),
  },
  {
    id: 'transDate',
    label: 'field.date',
    profile: ['html', 'excel'],
    render: ({ row }) => showDate(row.transDate),
    renderText: ({ row }) => showDate(row.transDate),
  },
  {
    id: 'ticketNo',
    label: 'field.ticketNo',
    profile: ['html', 'excel'],
  },
  {
    id: 'memo',
    label: 'field.memo',
    profile: ['excel'],
  },
  {
    id: 'spu',
    label: 'field.partName',
    profile: ['excel'],
  },
  {
    id: 'sku',
    label: 'field.sku',
    profile: ['excel'],
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    profile: ['excel'],
  },
  {
    id: 'status',
    label: 'field.status',
    profile: ['html', 'excel'],
    render: ({ row }) => (
      <StatusChip
        label={`status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    renderText: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <>
        <LinkButton
          mr={2}
          disabled={row.status === 'INACTIVE'}
          variant="icon"
          icon={<MdEdit />}
          href={`/adjust/${row.id}/edit`}
        />
        {renderDeleteButton({
          module: 'adjust',
          session,
          status: row.status,
          onSubmit: () =>
            handleDelete('partAdjust', {
              session,
              app,
              id: row.id,
              hash: row.hash,
              action,
            }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  session,
  app,
  message,
  setState,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
  handlerExport: async ({ filterValues, createExcel }) => {
    const title = 'adjust.title.list'
    const cols = columns({ message }).filter(({ profile = [] }) =>
      profile.includes('excel'),
    )
    const rows = await getExportData({ session, app, filterValues })
    createExcel({ message, title, cols, rows })
  },
})

async function getData({ session, app, pagination, filterValues }) {
  const variables = { input: getDataInput({ pagination, filterValues }) }
  const query = `
    query($input: TicketQueryInput) {
      partAdjustTicketCount(input: $input)
      partAdjustTickets(input: $input) {
        id
        ticketNo
        toLocationName
        transDate
        status
        hash
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return {}

  const { page, countPerPage } = pagination
  return {
    data: data.partAdjustTickets,
    pagination: getPagination(page, countPerPage, data.partAdjustTicketCount),
  }
}

async function getExportData({ session, app, filterValues }) {
  const variables = { input: getDataInput({ filterValues, joinItem: true }) }
  const query = `
    query($input: TicketQueryInput) {
      partAdjustTickets(input: $input) {
        id
        ticketNo
        toLocationName
        productVariantName
        sku
        quantity
        extra
        transDate
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  return data.partAdjustTickets.map((item) => {
    const { extra } = item
    item.billingName = extra.billCompany?.name
    item.memo = extra.memo
    return item
  })
}

function getDataInput({ filterValues, pagination, joinItem = false }) {
  const id = filterValues.find(({ id }) => id === 'id')
  const transDate = filterValues.find(({ id }) => id === 'transDate')
  const input = {
    joinItem,
    id: id ? id.value : null,
    transDate: transDate ? transDate.value : null,
    orderBy: [{ key: 'id', sort: 'DESC' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

import React from 'react'
import { request } from 'utilities/request'
import { Link } from 'components/core'
import { textFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'

export const filters = ({ filterInput, setFilterInput }) => [
  {
    id: 'name',
    label: 'part.field.partName',
    input: textFilter({ filterInput, setFilterInput }),
  },
  {
    id: 'sku',
    label: 'part.field.sku',
    input: textFilter({ filterInput, setFilterInput }),
  },
  {
    id: 'locationName',
    label: `inventory.field.tech`,
    input: textFilter({ filterInput, setFilterInput }),
  },
]

export const columns = ({ action }) => [
  {
    id: 'locationName',
    label: `inventory.field.tech`,
  },
  {
    id: 'productVariantName',
    label: 'part.field.partName',
  },
  {
    id: 'sku',
    label: 'part.field.sku',
  },
  {
    id: 'quantity',
    label: 'field.quantity',
    align: 'right',
    render: ({ row }) => (
      <Link
        variant="primaryLink"
        onClick={() =>
          action.handleOpen({
            locationId: row.locationId,
            productVariantId: row.productVariantId,
          })
        }
      >
        {row.quantity}
      </Link>
    ),
  },
]

export const handlers = ({
  session,
  app,
  setState,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({
      session,
      app,
      filterValues,
      pagination,
    })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ session, app, filterValues, pagination }) {
  const variables = {
    input: getDataInput({ filterValues, pagination }),
  }
  const query = `
    query($input: InventoryQueryInput) {
      inventoryCount(input: $input)
      inventoryList(input: $input) {
        locationId
        locationName
        productVariantId
        quantity
        productVariantName
        sku
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { session, app })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.inventoryList,
    pagination: getPagination(page, countPerPage, data.inventoryCount),
  }
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const locationName = filterValues.find(({ id }) => id === 'locationName')
  const input = {
    productVariantName: name ? name.value : null,
    sku: sku ? sku.value : null,
    locationType: 'TECHNICIAN',
    locationName: locationName ? locationName.value : null,
    orderBy: [{ key: 'locationName' }, { key: 'productVariantName' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

export default {
  'sell.title.list': '銷貨目錄',
  'sell.title.add': '新增銷貨單',
  'sell.title.edit': '修改銷貨單',
  'sell.title.view': '銷貨單詳情',
  'sell.section.basic': '基礎信息',
  'sell.section.part': '銷貨零件',
  'sell.field.repairId': '維修單號',
  'sell.field.receiptNo': '發票號碼',
}

import React from 'react'
import { Center } from 'components/core'
import { Select, TextInput } from 'components/form'

export const textFilter = ({ filterInput, setFilterInput }) => (
  <TextInput
    fieldProps={{ mx: 0, mb: 0, mt: [2, 0], flex: [1, 3], width: 1 }}
    value={filterInput}
    onChange={setFilterInput}
  />
)

export const selectFilter = ({
  filterInput,
  setFilterInput,
  isSearchable = false,
  options,
}) => (
  <Select
    fieldProps={{ mx: 0, mb: 0, mt: [2, 0], flex: [1, 3], width: 1 }}
    isSearchable={isSearchable}
    isClearable={false}
    options={options}
    value={filterInput}
    onChange={(item) => setFilterInput(item)}
  />
)

export const dateFilter = ({ filterInput, setFilterInput }) => (
  <Center flex={[1, 3]} mt={[2, 0]}>
    <TextInput
      mr={2}
      type="date"
      fieldProps={{ m: 0, flex: 1, width: 1 }}
      value={filterInput && filterInput[0]}
      onChange={(value) => setFilterInput([value, filterInput[1]])}
    />
    <TextInput
      type="date"
      fieldProps={{ m: 0, flex: 1, width: 1 }}
      value={filterInput && filterInput[1]}
      onChange={(value) => setFilterInput([filterInput[0], value])}
    />
  </Center>
)

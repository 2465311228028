import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import {
  initializeState,
  handleSelectChange,
  validateForm,
  showDate,
  getSelectOptions,
  getSelectOption,
} from 'utilities/form'
import { getDiff } from 'utilities/list'
import { request } from 'utilities/request'
import { Definition, Button, Table } from 'components/core'
import { Select, NumberInput } from 'components/form'
import { MdDelete } from 'react-icons/md'
import { printHtml } from 'utilities/print'
import {
  getDisplayBalance,
  getEditableBalance,
  handleDelete,
  setBalances,
} from 'actions/ticket'
import { fetchBalance, fetchBalances } from 'actions/inventory'

export const initialState = (value = {}) => ({
  id: value.id,
  status: value.status || 'PENDING',
  hash: value.hash,
  fromTechName: value.fromLocationName || '',
  toTechName: value.toLocationName || '',
  transDate: showDate(value.transDate),
  oldTicketItems: value.oldTicketItems || [],
  ticketItems: value.ticketItems || [],
  parts: value.parts || [],
  partOptions: getSelectOptions(value.parts),
  technicians: getSelectOptions(value.locations),
  ...initializeState({
    fromTechId: getSelectOption(
      value.locations,
      value.fromLocationId,
      'id',
      'name',
    ),
    toTechId: getSelectOption(
      value.locations,
      value.toLocationId,
      'id',
      'name',
    ),
  }),
})

const validation = {
  fromTechId: [{ type: 'required', message: 'error.required' }],
  toTechId: [{ type: 'required', message: 'error.required' }],
}

const defs = [
  { id: 'id', label: 'field.ticketId' },
  {
    id: 'transDate',
    label: 'field.date',
    render: (state) => showDate(state.transDate),
  },
  {
    id: 'fromTechId',
    label: 'move.field.fromTech',
    render: (state) => state.fromTechName,
  },
  {
    id: 'toTechId',
    label: 'move.field.toTech',
    render: (state) => state.toTechName,
  },
]

const columns = [
  { id: 'productVariantName', label: 'field.partName' },
  { id: 'sku', label: 'field.sku' },
  { id: 'location', label: 'part.field.location' },
  { id: 'price', label: 'field.price', align: 'right' },
  { id: 'quantity', label: 'field.quantity', align: 'right' },
]

export const labels = ({ state }) => {
  const content = defs.reduce((result, { id, label, render }) => {
    const value = render ? render(state) : state[id]
    result[id] = <Definition label={label} value={value} />
    return result
  }, {})

  content.ticketItems = <Table columns={columns} rows={state.ticketItems} />
  return content
}

export const fields = ({ app, session, state, setState, profile }) => {
  const onSelectChange = (id, callback) =>
    handleSelectChange(id, state, setState, validation, callback)

  return {
    fromTechId: (
      <Select
        isClearable={false}
        id="fromTechId"
        label="move.field.fromTech"
        placeholder="move.field.fromTech"
        options={state.technicians}
        value={state.fromTechId}
        onChange={onSelectChange('fromTechId')}
        errMsg={state.__error__.fromTechId}
      />
    ),
    toTechId: (
      <Select
        isClearable={false}
        id="toTechId"
        label="move.field.toTech"
        placeholder="move.field.toTech"
        options={state.technicians}
        value={state.toTechId}
        onChange={onSelectChange('toTechId')}
        errMsg={state.__error__.toTechId}
      />
    ),
    partFilter: (
      <Select
        id="partFilter"
        placeholder="field.partFilter"
        options={state.partOptions}
        value=""
        onChange={(item) => {
          if (!item) return
          const { value: productVariantId } = item
          onPartChange({ app, session, state, setState, productVariantId })
        }}
      />
    ),
    ticketItems: (
      <Table
        columns={[
          {
            id: 'productVariantName',
            label: 'field.partName',
          },
          {
            id: 'sku',
            label: 'field.sku',
          },
          {
            id: 'balance',
            label: 'field.balance',
            align: 'right',
            render: ({ row, index }) =>
              getDisplayBalance(
                profile,
                state.oldTicketItems[index]?.quantity,
                row.balance,
              ),
          },
          {
            id: 'quantity',
            label: 'field.quantity',
            width: '128px',
            render: ({ row, index }) => (
              <NumberInput
                fieldProps={{ m: 0 }}
                min="1"
                max={getEditableBalance(
                  profile,
                  state.oldTicketItems[index]?.quantity,
                  row.balance,
                )}
                value={row.quantity}
                onChange={(value) => {
                  const ticketItems = cloneDeep(state.ticketItems)
                  const product = ticketItems[index]
                  product.quantity = value
                  ticketItems.splice(index, 1, product)
                  setState({ ...state, ticketItems })
                }}
              />
            ),
          },
          {
            id: 'actions',
            align: 'right',
            render: ({ index }) => (
              <Button
                variant="icon"
                icon={<MdDelete />}
                onClick={() => {
                  const ticketItems = [...state.ticketItems]
                  ticketItems.splice(index, 1)
                  setState({ ...state, ticketItems })
                }}
              />
            ),
          },
        ]}
        rows={state.ticketItems}
      />
    ),
  }
}

async function onPartChange({
  app,
  session,
  state,
  setState,
  productVariantId,
}) {
  const balance = await fetchBalance(
    app,
    session,
    'TECHNICIAN',
    state.fromTechId.value,
    productVariantId,
  )
  const ticketItems = cloneDeep(state.ticketItems)
  const part = state.parts.find((item) => item.id === productVariantId)
  const ticketItem = {
    productVariantId,
    productVariantName: part.name,
    sku: part.sku,
    location: part.extra?.location,
    price: part.price,
    balance,
    quantity: 1,
  }
  ticketItems.push(ticketItem)
  setState({ ...state, ticketItems })
}

export const handlers = ({
  session,
  app,
  state,
  setState,
  message,
  history,
  profile,
  id,
}) => ({
  handleLoad: async () => {
    const data = await getData({ app, session, profile, id })
    setState(initialState(data))
  },
  handleSubmit: async (event) => {
    event.preventDefault()
    if (!validateForm({ state, setState, validation })) return

    const [ok, data] = id
      ? await editMove(state, app, session)
      : await addMove(state, app, session)
    if (!ok) return
    if (!id) id = data.addPartMoveTicket

    history.push(`/move/${id}/view`)
  },
  handleDelete: async () => {
    const { hash } = state
    const ok = await handleDelete('partMove', { session, app, id, hash })
    if (!ok) return false

    history.push('/move')
    return true
  },
  handlePrint: () => {
    const title = 'move.title.view'
    const field = defs.map(({ id, label, render }) => {
      const value = render ? render(state) : state[id]
      return { label, value }
    })
    const list = { columns, rows: state.ticketItems }
    const content = [
      { type: 'title', value: title },
      { type: 'field', value: field },
      { type: 'list', value: list },
    ]
    printHtml({ title, content, message })
  },
})

async function getData({ app, session, profile, id }) {
  const locationInput = { type: ['TECHNICIAN'] }
  const variables = { id, locationInput }
  const query = `
    query($id: ID, $locationInput: LocationQueryInput) {
      locations(input: $locationInput) {
        id
        name
      }
      parts {
        id
        name
        sku
        price
        extra
      }
      partMoveTicket(id: $id) {
        fromLocationId
        fromLocationName
        toLocationId
        toLocationName
        transDate
        status
        hash
      }
      partMoveTicketItems(ticketId: $id) {
        id
        productVariantId
        productVariantName
        sku
        quantity
        price
        extra
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return {}

  const ticket = data.partMoveTicket || {}
  const ticketItems = data.partMoveTicketItems?.map((item) => ({
    ...item,
    location: item.extra?.location,
    price: item.price,
  }))

  if (profile === 'edit' && ticketItems.length > 0) {
    const balances = await fetchBalances(
      app,
      session,
      'TECHNICIAN',
      ticket.fromLocationId,
      ticketItems.map((item) => item.productVariantId),
    )
    setBalances(ticketItems, balances)
  }

  return {
    id,
    hash: ticket.hash,
    fromLocationId: ticket.fromLocationId,
    fromLocationName: ticket.fromLocationName,
    toLocationId: ticket.toLocationId,
    toLocationName: ticket.toLocationName,
    transDate: ticket.transDate,
    status: ticket.status,
    ticketItems,
    oldTicketItems: cloneDeep(ticketItems),
    parts: data.parts,
    locations: data.locations,
  }
}

async function addMove(state, app, session) {
  const variables = { input: getSubmitInput(state) }
  const query = `
    mutation($input: TicketInput!) {
      addPartMoveTicket(input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

async function editMove(state, app, session) {
  const variables = { id: state.id, input: getSubmitInput(state) }
  const query = `
    mutation($id: ID!, $input: TicketInput!) {
      editPartMoveTicket(id: $id, input: $input)
    }
  `
  return request({ query, variables }, { session, app })
}

function getSubmitInput(state) {
  const { hash, fromTechId, toTechId, oldTicketItems } = state
  const ticketItems = state.ticketItems.map((item) => ({
    id: item.id,
    productVariantId: item.productVariantId,
    quantity: parseInt(item.quantity),
    price: parseFloat(item.price),
    extra: { location: item.location },
  }))
  const isKeyEqual = (item, newItem) => {
    return item.productVariantId === newItem.productVariantId
  }
  const isValEqual = (item, newItem) => {
    if (item.quantity !== newItem.quantity) return false
    if (item.extra?.location !== newItem.extra?.location) return false
    return true
  }
  const diff = getDiff(oldTicketItems, ticketItems, isKeyEqual, isValEqual)

  return {
    hash,
    fromLocationId: fromTechId.value,
    toLocationId: toTechId.value,
    itemsToAdd: diff.added,
    itemsToEdit: diff.modified.map((item) => item.after),
    itemsToDel: diff.removed.map((item) => item.id),
  }
}

import { formatDate } from 'utilities/date'

export function initializeState(value) {
  return {
    ...value,
    __error__: Object.keys(value).reduce((result, key) => {
      result[key] = ''
      return result
    }, {}),
  }
}

export function handleKeyPress(state, setState, id, pattern) {
  return (event) => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)

    if (!pattern.test(keyValue)) {
      event.preventDefault()
    }
  }
}

export function handleTextChange(id, state, setState, validation, callback) {
  return async (value) => {
    const { message } = validateField(value, validation[id], state)
    let callbackVal = {}

    if (callback) {
      callbackVal = await callback(value)
    }

    setState({
      ...state,
      ...callbackVal,
      [id]: value,
      __error__: { ...state.__error__, [id]: message },
    })
  }
}

export function handleSelectChange(id, state, setState, validation, callback) {
  return async (item) => {
    const value = item ? (Array.isArray(item) ? item : item.value) : null
    const { message } = validateField(value, validation[id], state)
    let callbackVal = {}

    if (callback) {
      callbackVal = await callback(item)
    }

    setState({
      ...state,
      ...callbackVal,
      [id]: item,
      __error__: { ...state.__error__, [id]: message },
    })
  }
}

export function getSelectOption(
  options,
  value,
  valueKey = 'value',
  labelKey = 'label',
  nullable = true,
) {
  if (!options || options.length === 0) return {}

  if (!value || Object.keys(value).length === 0) {
    if (nullable) return {}
    const option = options[0]
    return { value: option[valueKey], label: option[labelKey] }
  }

  const option = options.find((item) => item[valueKey] === `${value}`)
  if (!option) return {}
  return { value: option[valueKey], label: option[labelKey] }
}

export function getMultiSelectOption(
  options,
  value,
  valueKey = 'value',
  labelKey = 'label',
) {
  if (!options || options.length === 0) return []
  if (!value || value.length === 0) return []

  return value.map((itemVal) => {
    const option = options.find((item) => item[valueKey] === `${itemVal}`)
    return {
      value: option[valueKey],
      label: option[labelKey],
    }
  })
}

export function getSelectOptions(options, valueKey = 'id', labelKey = 'name') {
  if (!options) return []
  return options.map((item) => ({
    label: item[labelKey],
    value: item[valueKey],
  }))
}

export function getDate(date) {
  return formatDate(date ? new Date(date) : new Date())
}

export function showDate(date) {
  if (!date) return null
  return formatDate(new Date(date))
}

export function showAddress(value, message) {
  if (typeof value === 'string') return value

  const { zipcode, city, district, street, hasLift = false } = value
  let result = ''
  if (zipcode) result += `${zipcode} `
  if (city) result += city
  if (district) result += district
  if (street) result += street
  if (result.trim() !== '') {
    const msg = message({ id: `elevator.${hasLift}` })
    result += ` [${msg}]`
  }

  return result
}

export function parseAddress(cityMap, cities, districtMap, districts, address) {
  address = beforeParseAddress(address)
  const [city, rest] = extractStr(cities, address)
  const [district, street] = extractStr(districts, rest)

  let zipcode = null
  if (!zipcode && district) zipcode = districtMap[district]?.zipcode
  if (!zipcode && city) zipcode = cityMap[city]?.zipcode

  return { zipcode, city, district, street, hasLift: false }
}

function beforeParseAddress(address) {
  if (!address) return ''
  return `${address}`.replace('台', '臺')
}

function extractStr(options, value) {
  let matched = ''
  let rest = value

  for (const item of options) {
    const idx = value.indexOf(item)
    if (idx !== -1) {
      matched = item
      rest = value.substr(idx + item.length)
      break
    }
  }

  return [matched, rest]
}

export function handleTagChange(id, state, setState, validation) {
  return (items) => {
    setState({
      ...state,
      [id]: items,
    })
  }
}

export function validateForm({ state, setState, validation }) {
  const error = {}

  for (const [field, rules] of Object.entries(validation)) {
    const { hasError, message } = validateField(state[field], rules, state)

    if (hasError) {
      console.error(field, message)
      error[field] = message
    }
  }

  if (Object.keys(error).length > 0) {
    setState({
      ...state,
      __error__: error,
    })
    return false
  }

  return true
}

export function validateRows(rows, validation) {
  return rows.reduce((result, row) => {
    const { ticketNo } = row
    const [ok, message] = validateRow(row, validation)
    if (ok) return result

    result.push({ ticketNo, message })
    return result
  }, [])
}

function validateRow(row, validation) {
  for (const [field, rules] of Object.entries(validation)) {
    const { hasError, message } = validateField(row[field], rules, row)

    if (hasError) {
      console.error(field, message)
      return [false, message]
    }
  }

  return [true]
}

export function validateField(value, rules, state) {
  let hasError = false
  const message = ''

  if (!rules) {
    return { hasError, message: '' }
  }

  for (const validation of rules) {
    switch (validation.type) {
      case 'required':
        hasError = isEmpty(value)
        break
      case 'min':
        hasError = lessThanMin(value, validation.val)
        break
      case 'minLength':
        hasError = lessThanMinLength(value, validation.val)
        break
      case 'maxLength':
        hasError = moreThanMaxLength(value, validation.val)
        break
      case 'fieldEqual':
        hasError = notEqual(value, state[validation.name])
        break
      case 'address':
        hasError = invalidAddress(value, state[validation.name])
        break
      case 'func':
        hasError = validation.func(state)
        break
      default:
        hasError = true
        console.error('Validation rule not supported', validation.type)
    }

    if (hasError) {
      return { hasError, message: validation.message }
    }
  }

  return { hasError, message }
}

function isEmpty(value) {
  if (value === undefined || value === null || value === '') {
    return true
  }
  if (Object.keys(value).length === 0 && value.constructor === Object) {
    return true
  }
  if (Array.isArray(value) && value.length === 0) {
    return true
  }
  return false
}

function invalidAddress(value) {
  const { city, street } = value
  return !city || !street
}

function lessThanMin(value, minValue) {
  return value < minValue
}

function lessThanMinLength(value, minValue) {
  return value.length < minValue
}

function moreThanMaxLength(value, maxValue) {
  return value.length > maxValue
}

function notEqual(value, value2) {
  return value !== value2
}

export function allowInteger(e) {
  const key = getKeyPressed(e)
  const regex = /[0-9]/
  if (!regex.test(key)) {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false)
  }
}

export function allowDecimal(e) {
  const key = getKeyPressed(e)
  const regex = /[0-9]|\./
  if (!regex.test(key)) {
    e.preventDefault ? e.preventDefault() : (e.returnValue = false)
  }
}

function getKeyPressed(e) {
  if (e.type === 'paste') {
    let key = e.clipboardData.getData('text/plain')
    if (!key) key = e.clipboardData.getData('text')
    return key
  }
  return String.fromCharCode(e.keyCode || e.which)
}

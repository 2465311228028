import React from 'react'
import { request } from 'utilities/request'
import { Button, StatusChip } from 'components/core'
import { textFilter } from 'utilities/filter'
import { getLimit, getPagination } from 'utilities/pagination'
import { renderDeleteButton } from 'utilities/app'
import { MdEdit } from 'react-icons/md'
import { getStatusColor } from 'constants/status'

export const filters = ({ filterInput, setFilterInput }) => [
  {
    id: 'name',
    label: 'part.field.partName',
    input: textFilter({ filterInput, setFilterInput }),
  },
  {
    id: 'sku',
    label: 'part.field.sku',
    input: textFilter({ filterInput, setFilterInput }),
  },
]

export const columns = ({ app, session, message, action }) => [
  {
    id: 'name',
    label: 'part.field.partName',
  },
  {
    id: 'sku',
    label: 'part.field.sku',
  },
  {
    id: 'barcode',
    label: 'part.field.barcode',
  },
  {
    id: 'location',
    label: 'part.field.location',
  },
  {
    id: 'price',
    label: 'part.field.price',
  },
  {
    id: 'status',
    label: 'field.status',
    profile: ['html', 'excel'],
    render: ({ row }) => (
      <StatusChip
        label={`part.status.${row.status}`}
        color={getStatusColor(row.status)}
      />
    ),
    renderText: ({ row }) => message({ id: `status.${row.status}` }),
  },
  {
    id: 'actions',
    align: 'right',
    render: ({ row }) => (
      <>
        <Button
          mr={2}
          variant="icon"
          icon={<MdEdit />}
          onClick={() => action.handleOpen(row)}
        />
        {renderDeleteButton({
          module: 'part',
          session,
          status: row.status,
          onSubmit: () => handleDelete({ session, app, id: row.id, action }),
        })}
      </>
    ),
  },
]

export const handlers = ({
  session,
  app,
  setState,
  setFilterValues,
  setPagination,
}) => ({
  handleLoad: async ({ pagination = {}, filterValues = [] } = {}) => {
    const resp = await getData({ session, app, pagination, filterValues })
    setFilterValues(filterValues)
    setState(resp.data)
    setPagination(resp.pagination)
  },
})

async function getData({ session, app, pagination, filterValues }) {
  const variables = { input: getDataInput({ pagination, filterValues }) }
  const query = `
    query($input: ProductQueryInput) {
      partCount(input: $input)
      parts(input: $input) {
        id
        name
        sku
        barcode
        price
        extra
        status
      }
    }
  `
  const [ok, data] = await request({ query, variables }, { app, session })
  if (!ok) return []

  const { page, countPerPage } = pagination
  return {
    data: data.parts,
    pagination: getPagination(page, countPerPage, data.partCount),
  }
}

function getDataInput({ filterValues, pagination }) {
  const name = filterValues.find(({ id }) => id === 'name')
  const sku = filterValues.find(({ id }) => id === 'sku')
  const input = {
    productVariantName: name ? name.value : null,
    sku: sku ? sku.value : null,
    orderBy: [{ key: 'name' }],
  }
  if (pagination) input.limit = getLimit(pagination)
  return input
}

async function handleDelete({ session, app, id, action }) {
  const variables = { id }
  const query = `
    mutation($id: ID!) {
      deletePart(id: $id)
    }
  `
  const [ok] = await request({ query, variables }, { session, app })
  if (ok && action) action.handleLoad()
  return ok
}

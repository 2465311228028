import React, { useContext, useState, useEffect } from 'react'
import { SessionContext, AppContext } from 'contexts'
import { columns, filters, handlers } from 'models/inventoryAllList'
import { Section, Table } from 'components/core'
import Page from 'components/Page'
import InventoryDrawer from 'views/InventoryDrawer'
import { initialPage } from 'utilities/pagination'

export default () => {
  const session = useContext(SessionContext)
  const app = useContext(AppContext)
  const [state, setState] = useState()
  const [filterValues, setFilterValues] = useState([])
  const [pagination, setPagination] = useState(initialPage())
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState({})
  const { handleLoad } = handlers({
    setState,
    session,
    app,
    setFilterValues,
    setPagination,
  })

  useEffect(() => {
    handleLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpen = (item) => {
    setValue(item)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setValue({})
  }

  return (
    <Page title="inventory.title" navId="inventoryList">
      <Section>
        <Table
          columns={columns({ action: { handleOpen } })}
          rows={state}
          filters={filters}
          filterValues={filterValues}
          pagination={pagination}
          onChange={handleLoad}
        />
      </Section>
      <InventoryDrawer value={value} open={open} action={{ handleClose }} />
    </Page>
  )
}

export default {
  'move.title.list': '調撥目錄',
  'move.title.add': '新增調撥單',
  'move.title.edit': '修改調撥單',
  'move.title.view': '調撥單詳情',
  'move.btn.add': '新增調撥',
  'move.section.basic': '基礎信息',
  'move.section.part': '調撥零件',
  'move.field.fromTech': '從維修人員',
  'move.field.toTech': '至維修人員',
}

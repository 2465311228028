import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Box, Flex } from 'reflexbox'
import {
  Center,
  Pagination,
  Text,
  Button,
  Chip,
  NoData,
  Link,
} from 'components/core'
import { Select } from 'components/form'
import { MdSearch } from 'react-icons/md'

const Table = ({ children, ...props }) => (
  <Box
    p={2}
    as="table"
    width={1}
    color="dark.2"
    sx={{ borderCollapse: 'collapse', position: 'relative' }}
    {...props}
  >
    {children}
  </Box>
)

const Row = ({ index, children, ...props }) => (
  <Box as="tr" bg={index % 2 === 0 ? 'light.1' : 'transparent'} {...props}>
    {children}
  </Box>
)

const EmptyRow = ({ columnCount }) => (
  <Box as="tr" bg="light.1">
    <Cell
      colSpan={columnCount}
      sx={{
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: 'light.3',
      }}
    >
      <NoData flexDirection="row" iconSize="22px" />
    </Cell>
  </Box>
)

const HeadCell = ({ column, ...props }) => {
  const { label, align, renderHead } = column
  return (
    <Box
      as="th"
      py={3}
      px={2}
      sx={{
        borderBottom: 'none',
        textAlign: align || 'left',
        fontWeight: 300,
        whiteSpace: 'nowrap',
      }}
      {...props}
      {...column.headProps}
    >
      {renderHead ? renderHead() : label && <FormattedMessage id={label} />}
    </Box>
  )
}

const Cell = ({ align, width, children, ...props }) => (
  <Box
    as="td"
    py={3}
    px={2}
    width={width || 'auto'}
    color="dark.1"
    sx={{
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: 'light.3',
      textAlign: align || 'left',
    }}
    {...props}
  >
    {children}
  </Box>
)

const initFilter = (filters, message) => {
  if (filters.length === 0) return {}

  const defaultFilter = filters[0]
  return {
    value: defaultFilter.id,
    label: message({ id: defaultFilter.label }),
  }
}

export default ({
  filters,
  filterValues = [],
  columns,
  rows,
  footer,
  pagination,
  onChange,
  headProps,
  bodyProps,
  rowProps,
  cellProps,
  ...props
}) => {
  const { formatMessage: message } = useIntl()
  const [filterOp, setFilterOp] = useState('')
  const [filterInput, setFilterInput] = useState('')
  const configs = filters
    ? filters({ message, filterOp, setFilterOp, filterInput, setFilterInput })
    : []
  const [filterSelect, setFilterSelect] = useState(initFilter(configs, message))

  const renderCell = (column, row, index) => {
    if (column.render) {
      return column.render({ row, index })
    }
    if (column.renderText) {
      return column.renderText({ row, index })
    }
    return <Text>{row[column.id]}</Text>
  }

  const renderFilterSelector = () => (
    <Select
      fieldProps={{ mt: 0, mr: [0, 2], flex: 1, width: 1 }}
      isSearchable={false}
      isClearable={false}
      options={configs.map((item) => ({
        value: item.id,
        label: message({ id: item.label }),
      }))}
      value={filterSelect}
      onChange={(value) => {
        const config = configs.find(({ id }) => id === value.value)
        setFilterOp(config.defaultOp || '')
        setFilterInput(config.defaultValue || '')
        setFilterSelect(value)
      }}
    />
  )

  const renderFilterInput = () =>
    configs.find((item) => item.id === filterSelect.value).input

  const renderFilterOp = () =>
    configs.find((item) => item.id === filterSelect.value).op

  const submitFilter = () => {
    if (!filterInput || filterInput.length === 0) return

    const filterVals = [...filterValues]
    const newFilter = {
      id: filterSelect.value,
      op: filterOp?.value,
      value: filterInput,
    }
    const index = filterVals.findIndex(({ id }) => filterSelect.value === id)
    if (index === -1) {
      filterVals.push(newFilter)
    } else {
      filterVals.splice(index, 1, newFilter)
    }

    onChange({ filterValues: filterVals, pagination })
    setFilterInput('')
  }

  const renderFilterButton = () => (
    <Button
      mt={[2, 0]}
      ml={[0, 2]}
      icon={<MdSearch size="24px" />}
      variant="icon"
      type="submit"
    />
  )

  const getFilterLabel = ({ id, value }) => {
    const filter = configs.find((item) => item.id === id)
    if (filter.getLabel) return filter.getLabel(value)
    const getValue = () => {
      if (typeof value === 'string') return value
      if (Array.isArray(value)) return value.join(', ')
      if (value.label) return value.label
      return ''
    }
    return (
      <Center>
        <Text mr={1}>{message({ id: filter.label })}:</Text>
        {getValue(value)}
      </Center>
    )
  }

  const renderClearButton = () => (
    <Link
      variant="primaryLink"
      text="filter.clear"
      fontSize={1}
      onClick={() => onChange({ filterValues: [], pagination })}
    />
  )

  const cols = columns
    .filter(({ profile = ['html'] }) => profile.includes('html'))
    .filter(({ show = true }) => show)
  return (
    <Box {...props}>
      {configs && configs.length > 0 && (
        <Flex
          flexDirection={['column', 'row']}
          alignItems="center"
          px={2}
          width={1}
          as="form"
          onSubmit={(event) => {
            event.preventDefault()
            submitFilter()
          }}
        >
          {renderFilterSelector()}
          <Flex flex={[1, 3]} width={1} alignItems="center">
            {renderFilterOp()}
            {renderFilterInput()}
          </Flex>
          {renderFilterButton()}
        </Flex>
      )}
      {filterValues.length > 0 && (
        <Flex alignItems="center" p={2} width={1}>
          {filterValues.map((item, index) => (
            <Chip
              mr={2}
              key={index}
              text={getFilterLabel(item)}
              onDelete={() => {
                const filterVals = [...filterValues]
                filterVals.splice(index, 1)
                onChange({ filterValues: filterVals, pagination })
              }}
            />
          ))}
          {renderClearButton()}
        </Flex>
      )}
      <Box
        height="100%"
        sx={{ overflowY: 'visible', overflowX: 'auto' }}
        {...bodyProps}
      >
        <Table>
          <thead>
            <Row>
              {cols.map((column) => (
                <HeadCell key={column.id} column={column} {...headProps} />
              ))}
            </Row>
          </thead>
          <tbody>
            {rows && rows.length > 0 ? (
              rows.map((row, index) => (
                <Row key={index} index={index} {...rowProps}>
                  {cols.map((column) => (
                    <Cell
                      key={column.id}
                      align={column.align}
                      width={column.width}
                      {...cellProps}
                      {...column.cellProps}
                    >
                      {renderCell(column, row, index)}
                    </Cell>
                  ))}
                </Row>
              ))
            ) : (
              <EmptyRow columnCount={cols.length} />
            )}
          </tbody>
          {footer && <tfoot>{footer}</tfoot>}
        </Table>
      </Box>
      {pagination && pagination.totalPage > 1 && (
        <Box
          pt={3}
          px={2}
          width={1}
          sx={{
            borderTopWidth: '1px',
            borderTopStyle: 'solid',
            borderTopColor: 'light.3',
          }}
        >
          <Pagination
            value={pagination}
            onClick={(page) => {
              pagination.page = page
              onChange({ pagination, filterValues })
            }}
          />
        </Box>
      )}
    </Box>
  )
}
